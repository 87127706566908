import { getAccountFlow } from "@/lib/api/secure/accounts-service"
import { mapFieldNamesToSections } from "@/lib/api/adapters/accounts"
import { usePreapprovalLocator } from "@/store/preapproval/composables"
import { RouteConfig } from "vue-router"
import { ExternalRouteLinks } from "./external-routes"
import analytics, { logError, logWarning } from "@/lib/analytics"
import {
  useAccountStore,
  useRegistrationStore
} from "@/store"
import { isRuntimeError } from "@/lib/util/errors"

export const OtherHomeRouteName = "other_home"
export const HomeRouteName = "home"
export const Step1RouteName = "step1"
export const Step2RouteName = "step2"
export const UpdateRouteName = "update"
export const ElectronicBankingRouteName = "electronic_banking"

export const LeadsInterstitialRouteName = "leads"
export const LeadsFlowName = "leads"
export const NcLeadsInterstitialRouteName = "nc_leads"
export const PreapprovalRouteName = "approved"
export const PreapprovalUpswingRouteName = "upswingapproved"
export const OffersRouteName = "offers"
export const ContractRouteName = "contract"
export const NetcreditRedirectRouteName = "netcredit_redirect"
export const RefinanceOffersRouteName = "refinance_offers"
export const LeadRedirectRouteName = "lead_redirect"
export const AHRedirectRouteName = "account_home_redirect"

export const UpdateAndApplyText = "Update & Apply"

export const orchestratorRouteMappper: Record<string, string> = {
  "reg1": Step1RouteName,
  "reg2": Step2RouteName,
  "offers": OffersRouteName,
  "ebs_pre_contract": ElectronicBankingRouteName,
  "contract": ExternalRouteLinks.NewContract,
  "preapproval": PreapprovalRouteName,
  "upswing_approved": PreapprovalUpswingRouteName,
  "customer_update": UpdateRouteName,
  "refi_offers": RefinanceOffersRouteName,
  "fail_underwriting": ExternalRouteLinks.AccountHome,
  "fail_available_offers": ExternalRouteLinks.AccountHome,
  "account_home": ExternalRouteLinks.AccountHome,
  "login": ExternalRouteLinks.LoginExistingCustomer,
  "leads_login": ExternalRouteLinks.LoginLeadsCustomer,
  "signed_contract": ExternalRouteLinks.ContractSubmitted,
  "nc_interstitial": NcLeadsInterstitialRouteName,
  "import_rejected": ExternalRouteLinks.NcImportRejected,
  "import_failed": Step1RouteName,
  "await_email_confirmation": ExternalRouteLinks.ConfirmEmail,
  "await_send_email_confirmation": ExternalRouteLinks.SendEmail,
  "leads_interstital": LeadsInterstitialRouteName,
  "leads_error": ExternalRouteLinks.CnufeHomeLeadsError,
  "sd_update": UpdateRouteName,
  "paydatenew_update": UpdateRouteName
}

const webAcquisitionsRouteMappper: Record<string, string> = {}

Object.keys(orchestratorRouteMappper).forEach((key) => {
  webAcquisitionsRouteMappper[orchestratorRouteMappper[key]] = key
})

export const translateOrchestratorRoute = (orchRoute : string) : string => {
  return orchestratorRouteMappper[orchRoute]
}

export const translateWebAcquisitionsRoute = (webAcquisitionsRoute : string) : string => {
  return webAcquisitionsRouteMappper[webAcquisitionsRoute]
}

export const setRouteDefaults = (routes: Array<RouteConfig>): Array<RouteConfig> => {
  const defaultsMeta = {
    orchestratorRoute: ""
  }
  routes.forEach((r) => {
    defaultsMeta["orchestratorRoute"] = translateWebAcquisitionsRoute(r.name || "")

    r.meta = Object.assign({}, defaultsMeta, r.meta || {})
  })
  return routes
}

export const parseQueryParams = (query: Record<string, unknown>): void => {
  //TODO Figure out if this is because step2 does not have access to this data OR users have optoin
  //  to override (maybe via drop down) in CNUFE
  const { leadUUID, leadOfferId, leadProviderId, leadProviderName } = useRegistrationStore()

  if ("lead_uuid" in query) {
    leadUUID.value = String(query["lead_uuid"])
  }
  if ("lead_offer_id" in query) {
    leadOfferId.value = String(query["lead_offer_id"])
  }
  if ((leadOfferId.value.length && !leadUUID.value.length) || (leadUUID.value.length && !leadOfferId.value.length)) {
    logWarning("Leads mismatch")
  }
  if ("lead_provider_id" in query) {
    leadProviderId.value = String(query["lead_provider_id"])
  }
  if ("lead_provider_name" in query) {
    leadProviderName.value = String(query["lead_provider_name"])
  }
}

/*
 *  TODO:
 *    Delete after experiment is over, this is suboptimal as we are passing semi-sensitive data via URL
 */
/* istanbul ignore next */
export async function mapPreapprovalToStore(preapproval: string) : Promise<void>{
  const { formData, findPreapproval } = usePreapprovalLocator()
  const [offerCode, lastFourSSN] = String(preapproval).split("_")
  formData.offerCode.value = offerCode
  formData.lastFourSSN.value = lastFourSSN
  try{
    await findPreapproval()
  }
  catch(e: any){
    if(isRuntimeError(e)){
      logError(e)
    }
    else{
      analytics.track("automatching_preapproval_failed", { error_code: e && e.error_code })
    }
  }
}

export async function getLoggedInRouteName(): Promise<string> {
  // TODO: Add some sort of "home" flow to the orchestrator so we can break this logic out
  // and move people to the flow they should be in.
  let routeName = Step2RouteName
  const { formSections, flowButtonText, flowName, leadUUID } = useRegistrationStore()
  if (leadUUID.value.length) {
    routeName = LeadsInterstitialRouteName
  } else {
    let flows
    try {
      flows = await getAccountFlow()
    }catch(e: any){
      /* istanbul ignore if */
      if(isRuntimeError(e)){
        logError(e)
      }
    }
    if(flows){
      flowName.value = flows.application_flows[0]?.name.length ? flows.application_flows[0]?.name : ""
      const { eligibleForLoan } = useAccountStore()
      if (flows.application_flows[0]?.name === "has_active_loan" || !eligibleForLoan.value) {
        routeName = ExternalRouteLinks.CnufeHome
      }
      else if (flows.application_flows[0]?.name !== "incomplete_customer") {
        const sections: Array<string> = []
        for (const field of flows.application_flows[0]?.incomplete_fields ?? []) {
          sections.push(field.name)
        }
        const mappedSections = mapFieldNamesToSections(sections)
        formSections.value = mappedSections

        flowButtonText.value = UpdateAndApplyText

        // If there's nothing to update, go to contract instead of the update page.
        routeName = !mappedSections.length ? ExternalRouteLinks.NewContract : UpdateRouteName
      }
    }
  }
  return Promise.resolve(routeName)
}
