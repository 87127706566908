import { RouteConfig } from "vue-router"
import { useRegistrationStore, useAccountStore, useOfferStore, useSessionStore, useContractStore } from "@/store"
import { getRequiredActions } from "@/lib/api/secure/required-actions-service"
import { logError } from "@/lib/analytics"

type buildUrlPayload = {
  suffix?: string;
  site?: string;
  query?: Record<string, string>
}

function buildUrl(args?: buildUrlPayload): string {
  const defaultUrl = args?.site == "web-acquisitions" ? process.env.VUE_APP_WEBACQ_BASE_URL : process.env.VUE_APP_CNUFE_BASE_URL ?? ""
  const protocol = args?.site == "web-acquisitions" ? process.env.VUE_APP_WEBACQ_PROTOCOL : process.env.VUE_APP_CNUFE_PROTOCOL ?? ""

  if (!args)
    return `${protocol}${defaultUrl}`

  let url = `${protocol}${defaultUrl}`
  url = args?.suffix ? `${url}/${args.suffix}` : url

  if (args.query) {
    url = `${url}?${new URLSearchParams(args.query).toString()}`
  }

  return url
}

const linkBuilders: Record<string, (params: buildUrlPayload) => string> = {
  "NewContract": (params: buildUrlPayload) => {
    return buildUrl(params)
  },
  "ContractPage": (params: buildUrlPayload) => {
    return buildUrl(params)
  },
  "ContractCreate": (params: buildUrlPayload) => {
    return buildUrl(params)
  },
  "AccountHome": (params: buildUrlPayload) => {
    return buildUrl(params)
  },
  "LoginLeadsCustomer": (params: buildUrlPayload) => {
    return buildUrl(params)
  }
}

const links: Record<string, string> = {
  "CnufeHome": buildUrl(),
  "CnufeHomeLeadsError": buildUrl({ query: { leads_redirect: "inactive_lead" } }),
  "ApplyNow": buildUrl({ site: "web-acquisitions" }),
  "ContactUs": buildUrl({ suffix: "contact-us.html" }),
  "Faq": buildUrl({ suffix: "faq.html" }),
  "RatesAndTerms": buildUrl({ suffix: "rates-and-terms" }),
  "WhatWeOffer": buildUrl({ suffix: "what-we-offer.html" }),
  "AboutUs": buildUrl({ suffix: "about-us.html" }),
  "Blog": buildUrl({ suffix: "blog/" }),
  "PrivacyPolicy": buildUrl({ suffix: "privacy-policy.html" }),
  "CaliforniaPrivacyPolicy": buildUrl({ suffix: "california-privacy-policy.html" }),
  "TermsOfUse": buildUrl({ suffix: "terms-of-use.html" }),
  "OptOut": buildUrl({ suffix: "opt_out.html" }),
  "PaydayLoans": buildUrl({ suffix: "payday-loan-alternatives" }),
  "CashAdvance": buildUrl({ suffix: "cash-advance" }),
  "OnlineLoans": buildUrl({ suffix: "online-loans" }),
  "InstallmentLoans": buildUrl({ suffix: "installment-loans" }),
  "LineOfCredit": buildUrl({ suffix: "personal-line-of-credit" }),
  "FastCash": buildUrl({ suffix: "quick-loans" }),
  "OnlineSecurity": buildUrl({ suffix: "consumer-notices" }),
  "Login": buildUrl({ suffix: "login" }),
  "LoginInactivity": buildUrl({ suffix: "login", query: { error: "inactivity" } }),
  "LoginExistingCustomer": buildUrl({ suffix: "login", query: { error: "existing_customer" } }),
  "Logout": buildUrl({ suffix: "logout" }),
  "ConsentObtainCreditReports": buildUrl({ suffix: "contracts/ocrconsent" }),
  "ConsentElectronicCommunications": buildUrl({ suffix: "contracts/econsent" }),
  "Upswing": buildUrl({ suffix: "upswing" }),
  "ManageDocuments": buildUrl({ suffix: "profile/documents" }),
  "AccountActivity": buildUrl({ suffix: "details/activity" }),
  "AccountHistory": buildUrl({ suffix: "details/history" }),
  "PaymentsDetails": buildUrl({ suffix: "payments/details" }),
  "ProfilePersonal": buildUrl({ suffix: "profile/personal" }),
  "ProfileEmployment": buildUrl({ suffix: "profile/employment " }),
  "ProfileContact": buildUrl({ suffix: "profile/contact" }),
  "ProfilePassword": buildUrl({ suffix: "profile/password" }),
  "ContractCreate": buildUrl({ suffix: "contracts/create" }),
  "ContractSubmitted": buildUrl({ suffix: "contracts/submitted" }),
  "PageNotFound": buildUrl({ suffix: "404.html" }),
  "RefiOffers": buildUrl({ suffix: "refinance" }),
  "ConfirmEmail": buildUrl({ suffix: "email_confirmation/confirm", query: { orch: "true" } }),
  "SendEmail": buildUrl({ suffix: "email_confirmation", query: { orch: "true" } }),
  "NcImportRejected": buildUrl({ suffix: "nc-import-rejected" })
}

const externalRoutes: Record<string, RouteConfig> = {}
const ExternalRouteLinks: Record<string, string> = {}

Object.keys(links).concat(Object.keys(linkBuilders)).forEach((key) => {
  ExternalRouteLinks[key] = key
  externalRoutes[key] = {
    path: `/${key.toLowerCase()}`,
    name: key,
    beforeEnter: async (to, from, next) => {
      let listening = false
      const { resetState : resetRegistrationState, offerLead, stateCode } = useRegistrationStore()
      const { resetState : resetSessionState, clearOrchestratorFlow } = useSessionStore()
      const { resetState : resetAccountState } = useAccountStore()
      const { resetState : resetContractState } = useContractStore()
      const { selectedOffer } = useOfferStore()

      if (typeof window.addEventListener === "function") {
        const unloadEvent = "onpagehide" in window ? "pagehide" : "unload"
        window.addEventListener(
          unloadEvent,
          () => {
            resetAccountState()
            resetRegistrationState()
            resetContractState()
          },
          false
        )
        listening = true
      }

      // pre-contract ebs verification
      if (key.match(/contract/ig)) {
        try {
          const { required_actions = []} = await getRequiredActions()
          if (required_actions.includes("pre_contract_ebs")) {
            next({ path: "/electronic_banking" })
            return
          }
        } catch (err) {
          logError(`router/external-routes: Failed to pull required actions ${err}`, err?.message)
        }
      }

      // "referer" intentionally misspelled below to align with the rails request.referer spelling;
      // if changing the spelling, make sure to change it in CNUFE as well!
      if (key === "NewContract") {
        window.location.href = linkBuilders[key]({ suffix: "contracts/new", query: { source: "web_acq", referer: from.name ?? "" } })
      } else if(key === "ContractPage") {
        window.location.href = linkBuilders[key]({ suffix: "contracts/contract", query: { source: "web_acq" } })
      } else if(key === "ContractCreate") {
        let createContractPath = "contracts/create"
        if(selectedOffer.value) {
          createContractPath = "contracts/create/" + selectedOffer.value.uuid
        }
        window.location.href = linkBuilders[key]({ suffix: createContractPath, query: { source: "web_acq" } })
      } else if(key === "AccountHome") {
        clearOrchestratorFlow()
        window.location.href = linkBuilders[key]({ suffix: "account_home", query: { source: "web_acq", referer: from.name ?? "" } })
      } else if (key === "LoginLeadsCustomer") {
        const providerCode = offerLead.value ? "ow" : "sb"
        window.location.href = linkBuilders[key]({
          suffix: "login",
          query: {
            source: "web_acq",
            referer: from.name ?? "",
            provider: providerCode,
            state_cd: stateCode.value ?? ""
          } })
      } else if (key === "Logout" || key === "LoginInactivity") {
        resetSessionState()
        window.location.href = links[key]
      } else if (key === "SendEmail") {
        window.location.href = links[key]
      }
      else {
        clearOrchestratorFlow()
        window.location.href = links[key]
      }
      if (!listening) {resetRegistrationState()}
    }
  }
})

export default {}
export { externalRoutes, ExternalRouteLinks }
