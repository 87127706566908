import { RouteConfig } from "vue-router"
import { mapQueryToStore } from "@/lib/api/adapters/seo-tracking"
import { ExternalRouteLinks } from "./external-routes"
import analytics, { logError } from "@/lib/analytics"
import { FormSections, FlowActions } from "@/lib/models"
import {
  OtherHomeRouteName, HomeRouteName, Step1RouteName, Step2RouteName, UpdateRouteName,
  ElectronicBankingRouteName, LeadsInterstitialRouteName, LeadsFlowName,
  PreapprovalRouteName, PreapprovalUpswingRouteName, OffersRouteName,
  ContractRouteName, parseQueryParams, mapPreapprovalToStore, getLoggedInRouteName,
  setRouteDefaults, NcLeadsInterstitialRouteName,
  NetcreditRedirectRouteName, RefinanceOffersRouteName,
  LeadRedirectRouteName, AHRedirectRouteName
} from "./routing-helpers"

const Step1 = () => import("@/views/step-1.vue")
const Step2 = () => import("@/views/step-2.vue")
const Preapproval = () => import("@/views/preapproval.vue")
const ElectronicBanking = () => import("@/views/electronic-banking.vue")
const Leads = () => import("@/views/leads.vue")
const Update = () => import("@/views/update.vue")
const Offers = () => import("@/views/offers.vue")
const OffersTexas = () => import("@/views/offers-texas.vue")
const RefinanceOffers = () => import("@/views/refinance-offers.vue")
const Contract = () => import("@/views/contract.vue")
const NetcreditRedirect = () => import("@/views/netcredit-redirect.vue")
const NcLeadsInterstitial = () => import("@/views/nc-leads.vue")
const LeadRedirect = () => import("@/views/lead-redirect.vue")
const AHRedirect = () => import("@/views/ah-redirect.vue")
// this simply tells webpack to start working on these imports as soon as the bundle is done.
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "step-1" */
  "@/views/step-1.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "step-2" */
  "@/views/step-2.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "preapproval" */
  "@/views/preapproval.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "electronic-banking" */
  "@/views/electronic-banking.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "leads" */
  "@/views/leads.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "update" */
  "@/views/update.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "offers" */
  "@/views/offers.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "offers-texas" */
  "@/views/offers-texas.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "contract" */
  "@/views/contract.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "netcredit-redirect" */
  "@/views/netcredit-redirect.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "refinance-offers" */
  "@/views/refinance-offers.vue"
)
import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "refinance-offers" */
  "@/views/ah-redirect.vue"
)

import {
  useAccountStore,
  useRegistrationStore,
  useSessionStore,
  useApprovalStore,
  useContractStore
} from "@/store"

import { isRuntimeError, isUserOrDataError } from "@/lib/util/errors"

// TODO: Remove this route after a fix for /home white screens is addressed in KRKN-1930
const OtherHomeRoute: RouteConfig = {
  path: "/home",
  name: OtherHomeRouteName,
  component: Step1,
  beforeEnter: async (to, from, next) => {
    analytics.track("homeRouteError", { fromPath: from.path })
    next({ name: HomeRouteName })
  }
}

const HomeRoute: RouteConfig = {
  path: "/",
  name: HomeRouteName,
  component: Step1,
  beforeEnter: async (to, from, next) => {
    const { resetState } = useRegistrationStore()
    let routeName = Step1RouteName
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) {
      resetState()
    }
    parseQueryParams(to.query)
    /* istanbul ignore next */
    if(to.query["preapproval"] && typeof to.query["preapproval"] === "string"){
      await mapPreapprovalToStore(to.query["preapproval"])
    }
    mapQueryToStore(to.query)
    if (isLoggedIn.value) {
      try {
        routeName = await getLoggedInRouteName()
      } catch (e: any) {
        /* istanbul ignore if */
        if(isRuntimeError(e)){
          logError(e)
        }
      }
    }
    next({ name: routeName })
  }
}

const UpdateRoute: RouteConfig = {
  path: "/update",
  name: UpdateRouteName,
  props: true,
  component: Update,
  beforeEnter: (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    const { eligibleForLoan } = useAccountStore()
    const { formSections } = useRegistrationStore()

    // There are 3 ways to reach this code:
    // 1. As a follow-on to getLoggedInRouteName() above. In this case we've
    //    done a lot of vetting of the customer state, and we definitely want
    //    to show the update page.
    // 2. By directly navigating to the update page, either by manually
    //    entering the URL or by accessing browser history. In these cases we
    //    won't know which sections to show, so it's best to just go to the
    //    home page instead of showing an empty page.
    // 3. By going to the ah-redirect page and using the orchestrator
    if (!isLoggedIn.value || !eligibleForLoan.value || !formSections?.value?.length) {
      next({ name: HomeRouteName })
      return
    }
    next()
  }
}

const LeadsInterstitialRoute: RouteConfig = {
  path: "/leads",
  name: LeadsInterstitialRouteName,
  props: true,
  component: Leads,
  beforeEnter: (to, from, next) => {
    const { isLoggedIn, accountId } = useSessionStore()
    const { eligibleForLoan } = useAccountStore()
    if (!isLoggedIn.value) {
      next({ name: HomeRouteName })
      return
    } else if (!eligibleForLoan.value) {
      next({ name: ExternalRouteLinks.CnufeHome })
      return
    }
    parseQueryParams(to.query)

    const { formSections, flowName, flowAction, flowButtonText, leadUUID, leadOfferId, leadProviderId, leadProviderName } = useRegistrationStore()
    const traits = {
      leadUUID: leadUUID.value,
      leadOfferId: leadOfferId.value,
      leadProviderId: leadProviderId.value,
      leadProviderName: leadProviderName.value
    }
    analytics.identify(accountId.value, traits)
    flowName.value = LeadsFlowName
    formSections.value = [FormSections.SourceOfIncome, FormSections.Employment, FormSections.Income, FormSections.CheckingAccount, FormSections.ContactPreferences]
    flowAction.value = FlowActions.LeadsUpdate
    flowButtonText.value = "Continue"
    next()
  }
}

const NcLeadsInterstitialRoute: RouteConfig = {
  path: "/nc_leads",
  name: NcLeadsInterstitialRouteName,
  props: true,
  component: NcLeadsInterstitial,
  beforeEnter: (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) {
      next({ name: HomeRouteName })
      return
    }
    next()
  }
}

const Step2Route: RouteConfig = {
  path: "/step2",
  name: Step2RouteName,
  component: Step2,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    const { hasLoans } = useAccountStore()

    if (!isLoggedIn.value) {
      next({ name: HomeRouteName })
      return
    } else if (hasLoans.value) {
      analytics.track("reg2hasLoans")
      next({ name: ExternalRouteLinks.CnufeHome })
      return
    }
    next()
  }
}

const Step1Route: RouteConfig = {
  path: "/step1",
  name: Step1RouteName,
  props: true,
  component: Step1,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (isLoggedIn.value) {
      next({ name: HomeRouteName })
    }
    next()
  }
}

export const PreapprovalRoute: RouteConfig = {
  path: "/approved",
  name: PreapprovalRouteName,
  props: true,
  component: Preapproval,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (isLoggedIn.value) {
      next({ name: HomeRouteName })
    }
    next()
  }
}

export const PreapprovalUpswingRoute: RouteConfig = {
  path: "/upswingapproved",
  name: PreapprovalUpswingRouteName,
  props: true,
  component: Preapproval,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (isLoggedIn.value) {
      next({ name: HomeRouteName })
    }
    next()
  }
}

export const OffersRoute: RouteConfig = {
  path: "/offers",
  name: OffersRouteName,
  props: true,
  component: () => {
    const { stateCode } = useAccountStore()
    const offersComponent = stateCode.value === "TX"
      ? OffersTexas
      : Offers

    return Promise.resolve({
      functional: true,
      render(h, { data, children }) {
        return h(offersComponent, data, children)
      }
    })
  },
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) {
      next({ name: HomeRouteName })
      return
    }
    next()
  }
}

export const RefinanceOffersRoute: RouteConfig = {
  path: "/refinance",
  name: RefinanceOffersRouteName,
  props: true,
  component: RefinanceOffers,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) {
      next({ name: ExternalRouteLinks.AccountHome })
      return
    }
    next()
  }
}

export const ContractRoute: RouteConfig = {
  path: "/contract",
  name: ContractRouteName,
  props: true,
  component: Contract,
  beforeEnter: async (to, from, next) => {
    const { accountId, isLoggedIn } = useSessionStore()
    const { loadContractData, resetState, lastSuccessfulSubmitId } = useContractStore()

    if (!isLoggedIn.value) {
      next({ name: HomeRouteName })
      return
    }
    if (lastSuccessfulSubmitId.value === accountId.value) {
      next({ name: ExternalRouteLinks.AccountHome })
      return
    }

    // Call the contract store action here to populate the store
    try {
      resetState()
      await loadContractData()
    } catch(e) {
      /* istanbul ignore if */
      next({ name: ExternalRouteLinks.AccountHome })
      return
    }
    next()
    return
  }
}

export const ElectronicBankingRoute: RouteConfig = {
  path: "/electronic_banking",
  name: ElectronicBankingRouteName,
  component: ElectronicBanking,
  beforeEnter: async (to, from, next) => {
    const { fetchApproval, needsPreContractEbs } = useApprovalStore()
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) return next({ name: HomeRouteName })

    try {
      await fetchApproval()

      if (needsPreContractEbs.value) {
        next()
        return
      }
    } catch (err) {
      /* istanbul ignore if */
      if (isUserOrDataError(err)) {
        logError("EBS Approval Pull, API Error", err)
      } else {
        logError("EBS Approval Pull, Generic Error", err?.message)
      }
    }

    next({ name: ExternalRouteLinks.AccountHome })
  }
}

export const NetcreditRedirectRoute: RouteConfig = {
  path: "/netcredit_redirect",
  name: NetcreditRedirectRouteName,
  component: NetcreditRedirect,
  beforeEnter: async (to, from, next) => {
    next()
  }
}

export const LeadRedirectRoute: RouteConfig = {
  path: "/lead_redirect",
  name: LeadRedirectRouteName,
  component: LeadRedirect,
  beforeEnter: async (to, from, next) => {
    next()
  }
}

export const AHRedirectRoute: RouteConfig = {
  path: "/account_home_redirect",
  name: AHRedirectRouteName,
  component: AHRedirect,
  beforeEnter: async (to, from, next) => {
    const { isLoggedIn } = useSessionStore()
    if (!isLoggedIn.value) return next({ name: HomeRouteName })
    next()
  }
}

const internalRoutes = setRouteDefaults([
  OtherHomeRoute,
  ContractRoute,
  ElectronicBankingRoute,
  HomeRoute,
  LeadsInterstitialRoute,
  NetcreditRedirectRoute,
  OffersRoute,
  PreapprovalRoute,
  PreapprovalUpswingRoute,
  Step1Route,
  Step2Route,
  UpdateRoute,
  NcLeadsInterstitialRoute,
  RefinanceOffersRoute,
  LeadRedirectRoute,
  AHRedirectRoute
])

export default {}
export { internalRoutes }
